import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url: "https://dev03.graphicloud.fr/", /*,*A CHANGER ABSOLUMENT POUR LA MISE EN LIGNE */
    globalData: {
      name: "Graphibox",
      adresse: "9-A rue de la tuilerie",
      codePostal: "32200",
      ville: "Gimont",
      pays: "France",
      telephone: "05 62 67 88 20",
      telephoneCountryCode: "+33 (0)5 62 67 88 20",
      email: "hello@graphibox.net",
      twitter: "https://twitter.com/GraphiboxMedias/",
      facebook: "https://www.facebook.com/MediasGraphibox/",
      linkedin: "https://fr.linkedin.com/company/graphibox-medias/",
      instagram: "https://www.instagram.com/agence_graphibox/?hl=fr",
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
