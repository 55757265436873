<template>
  <header class="main_menu">
    <div class="site_container">
      <router-link to="/" title="Page d'accueil" class="headlogo">
        <img src="/img/logos/logo-graphibox360_fullOrange.svg" alt="Logo Graphibox" />
      </router-link>

      <nav>
        <router-link to="/" title="Page d'accueil" class="the_menu_link"
          >WebService</router-link
        >
        <router-link to="/generator-password" title="Page password" class="the_menu_link">Password</router-link>

        
        

        <div class="superCenter">
          <a
            href="https://mail.graphibox.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="Graphibox Webmail"
            class="the_btn b_secondary"
          >
            Webmail
          </a>
        </div>

        <div class="MenuMobile">
          <div class="menuBtnTop" @click="showMenu = true">
            <i class="fas fa-bars"></i>
          </div>
          <!-- <div class="menuBtnFixed" @click="showMenu = true">
            <i class="fas fa-bars"></i>
          </div> -->

          <div class="contentMenu" v-if="showMenu == true">
            <i class="far fa-times-circle" @click="showMenu = false"></i>
            <a href="/" title="Page d'accueil" class="headlogo_mobile">
              <img src="/img/logos/Logo_Graphibox_white.svg" />
            </a>

            <!-- <a href="/contact" title="Contact" class="Estimer">
              <nuxt-img
                src="/img/logos/Estimer_projet.svg"
                alt="Estimer son projet"
              />
            </a> -->
            <a
              href="/agence-communication"
              title="L'agence"
              class="the_link firstBtn"
              >L'agence</a
            >
            <a href="/nos-box" title="Nos box" class="the_link ">Nos Box</a>
            <a
              href="/nos-realisations"
              title="Nos réalisations"
              class="the_link "
              >Nos réalisations</a
            >
            <a href="/actualites" title="L'actu" class="the_link ">L'actu</a>
            <a href="/contact#formulaire" title="Contact" class="the_link "
              >Contact</a
            >
            <p title="Contact" class="the_link " @click="showChooseBox = true">
              Estimer mon projet
            </p>
            <a
              href="https://mail.graphibox.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="Graphibox Webmail"
              class="the_btn b_secondary webmail_mobile webmailBtn"
            >
              Webmail
            </a>
            <a
              href="https://support.graphibox.fr/"
              target="_blank"
              rel="noopener noreferrer"
              title="Connexion - Espace Client / Support - Graphibox"
              class="the_btn b_secondary webmail_mobile"
            >
              <i class="fal fa-question-circle"></i>
              Support
            </a>

            <div class="reseauxSociaux">
              <a
                :href="FACEBOOK_CLIENT"
                target="_blank"
                rel="noopener noreferrer"
                title="Rejoignez-nous sur Facebook"
                class="fb_link"
              >
                <img
                  src="/img/icones/reseaux_sociaux/FacebookLogo.svg"
                  alt="Facebook"
                />
              </a>
              <a
                :href="TWITTER_CLIENT"
                target="_blank"
                rel="noopener noreferrer"
                title="Suivez-nous sur Twitter"
                class="tw_link"
              >
                <img
                  src="/img/icones/reseaux_sociaux/TwitterLogo.svg"
                  alt="Twiter"
                />
              </a>
              <a
                :href="LINKEDIN_CLIENT"
                target="_blank"
                rel="noopener noreferrer"
                title="Abonnez-vous à notre page Linkedin"
                class="lk_link"
              >
                <img
                  src="/img/icones/reseaux_sociaux/LinkedinLogo.svg"
                  alt="Linkedin"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>

    </div>
  </header>
</template>

<script>
export default {
  name: "Exemples",
  transition: "fade_page",

  data() {
    return {
      showMenu: false,

      FACEBOOK_CLIENT: this.$store.state.globalData.facebook,
      TWITTER_CLIENT: this.$store.state.globalData.twitter,
      LINKEDIN_CLIENT: this.$store.state.globalData.linkedin,

    };
  },

  created() {},

  beforeMount() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  async fetch() {},

  methods: {},
};
</script>

<style>
.main_menu {
  position: fixed;
  width: 100%;
  z-index: 2000;
  background-color: white;
  top: 0;
}

.main_menu .site_container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.main_menu .headlogo {
  padding-top: 0.5rem;
  width: 200px;
}

.main_menu .headlogo img {
  width: 100%;
}

.main_menu nav {
  display: flex;
  align-items: baseline;
}

.main_menu .MenuMobile {
  display: none;
}

.main_menu .MenuMobile .webmailBtn,
.main_menu .MenuMobile .headlogo_mobile {
  display: none;
}

.main_menu .MenuMobile .firstBtn {
  margin-top: 5rem !important;
}

@media all and (max-width: 1300px) {
  .main_menu nav {
    font-size: 1rem;
  }
}

@media all and (max-width: 1100px) {
  .main_menu nav .the_menu_link,
  .main_menu .MenuMobile .webmailBtn {
    display: none !important;
  }

  .main_menu nav .superCenter {
    margin-top: 1rem;
  }

  .main_menu .MenuMobile {
    display: block;
    color: var(--accent2-base);
    font-size: 1.5rem;
    margin: 0 1.5rem;
    cursor: pointer;
  }

  .main_menu .headlogo {
    margin-left: 2rem;
  }

  .main_menu .MenuMobile .contentMenu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-color: var(--secondary-base);
    color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }

  .main_menu .MenuMobile .contentMenu i {
    font-size: 3rem;
  }

  .main_menu .MenuMobile .contentMenu .Estimer {
    margin: 2rem 0 4rem calc(100% - 142px);
  }

  .main_menu .MenuMobile .contentMenu .the_link {
    color: white;
    margin-block: 1rem;
    font-size: 2rem;
  }

  .main_menu .MenuMobile .contentMenu .the_link::after {
    background: white;
  }

  .main_menu .MenuMobile .contentMenu .reseauxSociaux {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
  }

  .main_menu .MenuMobile .contentMenu .reseauxSociaux a {
    width: 40px;
    margin-bottom: 1rem;
  }

  .main_menu .MenuMobile .contentMenu .reseauxSociaux a img {
    width: 100%;
  }

  .main_menu .MenuMobile .webmail_mobile {
    display: flex;
    border-color: #fff;
    background: var(--secondary-base);
    color: #fff;
    width: fit-content;
    margin: 1rem 0;
    font-size: 1.8rem;
  }

  .main_menu .MenuMobile .webmail_mobile i {
    margin-right: 0.5rem;
    font-size: 1.8rem !important;
  }
}

@media all and (max-width: 768px) {
  .main_menu {
    position: relative;
  }

  .main_menu nav {
    width: 0;
  }

  .main_menu .MenuMobile .webmailBtn {
    display: block !important;
  }

  .main_menu .site_container {
    align-items: center;
  }

  .main_menu .MenuMobile .contentMenu {
    width: calc(100% - 4rem);
  }

  .main_menu .MenuMobile .contentMenu .Estimer {
    margin: 1rem 0 0rem calc(100% - 142px);
  }

  .main_menu .MenuMobile .contentMenu i {
    font-size: 3rem;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }

  .main_menu .MenuMobile .headlogo_mobile {
    display: block;
    width: 50%;
  }

  .main_menu .MenuMobile .headlogo_mobile img {
    width: 100%;
  }

  .main_menu .superCenter .b_secondary {
    display: none;
  }

  .main_menu .headlogo {
    width: 60%;
    margin: auto;
  }

  .main_menu .menuBtnTop {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    color: white;
    background-color: var(--secondary-base);
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--secondary-base);
    border-radius: 8px;
    font-size: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main_menu .MenuMobile .contentMenu .reseauxSociaux {
    top: 4rem;
    bottom: auto;
  }

  .main_menu .MenuMobile .webmail_mobile i {
    display: none;
  }
}

@media all and (max-width: 500px) {
  .main_menu .MenuMobile .contentMenu .the_link,
  .main_menu .MenuMobile .webmail_mobile {
    font-size: 1rem;
  }

  .main_menu .MenuMobile .contentMenu .reseauxSociaux a {
    width: 35px;
  }

  .main_menu .MenuMobile .contentMenu .Estimer {
    width: 35%;
    margin: 1rem 0 0rem calc(100% - 35%);
  }

  .main_menu .MenuMobile .contentMenu .Estimer img {
    width: 100%;
  }
}
</style>
