<template>
  <footer class="TheFooter">
    <div class="footerContainer">
      <div class="col">
        <img
          src="/img/Logos/logo_small.svg"
          alt="Logo Graphibox"
          class="footerContent logo_footer"
        />
        <div class="footerContent reseaux_sociaux">
          <a
            :href="FACEBOOK_CLIENT"
            target="_blank"
            rel="noopener noreferrer"
            title="Rejoignez-nous sur Facebook"
            class="fb_link"
          >
            <img
              src="/img/icones/reseaux_sociaux/FacebookLogo.svg"
              alt="Facebook"
          /></a>
          <a
            :href="LINKEDIN_CLIENT"
            target="_blank"
            rel="noopener noreferrer"
            title="Abonnez-vous à notre page Linkedin"
            class="lk_link"
            ><img
              src="/img/icones/reseaux_sociaux/LinkedinLogo.svg"
              alt="Linkedin"
          /></a>
          <a
            :href="TWITTER_CLIENT"
            target="_blank"
            rel="noopener noreferrer"
            title="Suivez-nous sur Twitter"
            class="tw_link"
          >
            <img
              src="/img/icones/reseaux_sociaux/TwitterLogo.svg"
              alt="Twiter"
            />
          </a>
          <a
            :href="INSTAGRAM_CLIENT"
            target="_blank"
            rel="noopener noreferrer"
            title="Suivez-nous sur Instagram"
            class="in_link"
          >
            <img
              src="/img/icones/reseaux_sociaux/instagram-brands.svg"
              alt="Instagram"
              width="24"
              height="24"
            />
          </a>
        </div>
        <div class="footerContent coordonnees">
          <img src="/img/icones/footer/MapPin.svg" alt="Adresse" />
          <p>
            {{ ADRESSE_CLIENT }} -- {{ VILLE_CLIENT }} {{ CODEPOSTAL_CLIENT }}
          </p>
        </div>
        <div class="footerContent coordonnees">
          <img src="/img/icones/footer/Phone.svg" alt="Adresse" />
          <a :href="'tel:' + PHONE_COUNTRY_CLIENT">{{ PHONE_CLIENT }}</a>
        </div>
        <div class="footerContent coordonnees">
          <img
            src="/img/icones/footer/EnvelopeSimpleOpen.svg"
            alt="Adresse"
          />
          <a :href="'mailto:' + EMAIL_CLIENT">{{ EMAIL_CLIENT }}</a>
        </div>
        <br />
      </div>

      <div class="col siteLinks">
          <router-link to="/" title="Page d'ccueil">Accueil</router-link>
          <a href="https://www.graphibox.biz/" target="_blank" title="L'agence" rel="noopener noreferrer">L'agence</a>
          <a
            href="https://support.graphibox.fr/"
            target="_blank"
            rel="noopener noreferrer"
            title="Connexion - Espace Client / Support - Graphibox"
          >
            Support
          </a>
          <a
            href="https://mail.graphibox.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="Graphibox Webmail"
          >
            Webmail
          </a>
          <a href="https://www.graphibox.biz/contact#formulaire" target="_blank" title="Contact" rel="noopener noreferrer">Contact</a>
        </div>
    </div>
    <div class="credit">
      <p>
        Copyright © 2021 Graphibox. Tous droits réservés -
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      FACEBOOK_CLIENT: this.$store.state.globalData.facebook,
      TWITTER_CLIENT: this.$store.state.globalData.twitter,
      LINKEDIN_CLIENT: this.$store.state.globalData.linkedin,
      INSTAGRAM_CLIENT: this.$store.state.globalData.instagram,
      ADRESSE_CLIENT: this.$store.state.globalData.adresse,
      CODEPOSTAL_CLIENT: this.$store.state.globalData.codePostal,
      VILLE_CLIENT: this.$store.state.globalData.ville,
      PHONE_CLIENT: this.$store.state.globalData.telephone,
      PHONE_COUNTRY_CLIENT: this.$store.state.globalData.telephoneCountryCode,
      EMAIL_CLIENT: this.$store.state.globalData.email,
    };
  },
};
</script>

<style>
.TheFooter {
  background-color: black;
  padding: 6rem;
}
.TheFooter .footerContainer {
  display: flex;
  justify-content: space-between;
}
.TheFooter .footerContainer .col {
  width: 30%;
}
.TheFooter .footerContainer .footerContent {
  padding: 0.5rem 0;
  display: flex;
  color: white;
  font-size: 0.9rem;
}
.TheFooter .footerContainer .logo_footer {
  filter: grayscale(1);
}
.TheFooter .footerContainer .reseaux_sociaux {
  justify-content: space-between;
  width: 35%;
}
.TheFooter .footerContainer .reseaux_sociaux a {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.TheFooter .footerContainer .reseaux_sociaux a img {
  width: 100%;
}
.TheFooter .footerContainer .reseaux_sociaux a.fb_link:hover {
  background: var(--secondary-base);
}
.TheFooter .footerContainer .reseaux_sociaux a.tw_link:hover {
  background: var(--accent-base);
}
.TheFooter .footerContainer .reseaux_sociaux a.lk_link:hover {
  background: var(--accent2-base);
}
.TheFooter .footerContainer .reseaux_sociaux a.in_link:hover {
  background: var(--primary-base);
}
.TheFooter .footerContainer .reseaux_sociaux i.fab.fa-instagram {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.TheFooter .footerContainer .coordonnees a,
.TheFooter .footerContainer .coordonnees p {
  margin-left: 1rem;
}
.TheFooter .footerContainer .coordonnees a:hover {
  color: var(--primary-base);
}
.TheFooter .credit {
  color: white;
  font-size: 0.7rem;
}
.TheFooter .footerContainer .siteLinks {
  display: flex;
  margin-top: 2rem;
  color: var(--grey-base);
  flex-direction: column;
  width: 20%;
}
.TheFooter .footerContainer .siteLinks a {
  position: relative;
  margin-bottom: 1.5rem;
  width: fit-content;
  z-index: 100;
}
.TheFooter .footerContainer .siteLinks a:after {
  content: "";
  background: #ff72d24b;
  display: block;
  height: 9px;
  width: 0;
  position: absolute;
  top: 30%;
  z-index: 1;
  transition: 0.3s ease-in;
}
.TheFooter .footerContainer .siteLinks a:hover::after {
  width: 100%;
}

@media all and (max-width: 1100px) {
  .TheFooter .footerContainer .siteLinks {
    flex-direction: column;
  }
}

@media all and (max-width: 768px) {
  .TheFooter .footerContainer {
    flex-direction: column;
  }
  .TheFooter .footerContainer .col {
    width: 100%;
  }
  .TheFooter .footerContainer .siteLinks {
    flex-direction: row;
    margin: 2rem 0;
  }
  .TheFooter {
    padding: 5rem;
  }
}

@media all and (max-width: 500px) {
  .TheFooter {
    padding: 5rem 2rem;
  }
  .TheFooter .footerContainer .siteLinks {
    flex-direction: column;
  }
}
</style>
