<template>
  <div class="home container">
    
    <h1>TEST WEBSERVICE</h1>
    <div class="flex spaceBetween">
      <div class="form container_input col">
        <h2>Requête</h2>
        <div class="fillWidth flex middleAlign">
            <label class="label" for="urlRequest">Protocole</label>
            <!-- <input class="the_input_text input_purple" type="text" name="URLProtocole" id="URLProtocole" v-model="URLProtocole" placeholder="https://"> -->
            <v-select v-model="URLProtocole" :options="['https', 'http']"/>
            <!-- <select name="URLProtocole" id="URLProtocole" v-model="URLProtocole" @input="URLProtocole">
              <option value="https">https</option>
              <option value="http">http</option>
            </select> -->
        </div>
        <div class="fillWidth flex middleAlign">
            <label class="label" for="urlRequest">URL</label>
            <input class="the_input_text input_purple" type="text" name="urlRequest" id="urlRequest" v-model="urlRequest" placeholder="ws2-gbbu02.graphibox.eu/...">
        </div>
        <div class="fillWidth flex">
            <div class="column column1">
                <input class="the_input_text input_grey label" v-for="(itemName, i) in pDataRequest" :key="'index : ' + i" v-model="itemName.name" >
            </div>
            <div class="column column2">
                <input class="the_input_text input_grey" v-for="(itemValue, j) in pDataRequest" :key="'index : ' + j" v-model="itemValue.value"  >
            </div>
        </div>

        <div class="flex container_btn spaceBetween">
          <button class="the_btn b_secondary" @click="addInputParam()">Ajouter un paramètre</button>
          <button class="the_btn b_accent2" @click="callWS()">TESTER</button>
        </div>
      </div>
      <div class="container_input col container_URL" >
        <h2>URL généré</h2>
        <div @click="CopyClipboard()" class="content_URL">{{ getUrl }}</div>
        <div v-if="getUrl" class="flex container_btn flexEnd">
          <button class="the_btn b_primary" @click="CopyClipboard()">Copier</button>
        </div>
        <div class="flex container_btn flexEnd rose" v-if="showMessage">
          Lien copié
        </div>
      </div>
    </div>

    
    
    
    <div class="container_resultat">
      <h2>Résultat</h2>
      <pre class="prettyprint">
        {{ getData }}
      </pre>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Graphibox à Gimont dans le Gers "
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "Graphibox, Gimont, Gers"
      }
    ]
  },
  components: {vSelect},
  name: 'Home',
  data() {
    return {
    URLProtocole: "https",
    urlRequest: "",
    //pKeyRequest: "",
    //pFctRequest: "",
    pDataRequest: [],
    getData: "", 
    getUrl: "",
    showMessage: false,

    }
  },
  mounted() {
      // this.addInputParam();

      // ?name=test
     let parameters = this.$route.query;
      let pUrl = this.$route.query.pUrl;

      for(var item in parameters) {
        if(item == "pUrl") {
          this.urlRequest = parameters[item];
        } else if(item == "pProt") {
          this.URLProtocole = parameters[item];
        } else {
          this.addInputParam(item, parameters[item]);
        }
      }

      // nbItem = parameters.Count
      // For x = 0 to nbItem
      //        paraName = this.$route.query.[x];
      //        paraValue = this.$route.query.x;
      //
      // addInputParam() {
      //    this.pDataRequest.push({
      //        name: paraName,
      //        value: paraValue
      // });

      // console.log('#1 ' + parameters);
      // console.log('#2' + pUrl);

      //     if (pUrl != null && pUrl != '') {
      //         this.addInputParam('pUrl', pUrl);
      // }

      // console.log(this.pDataRequest)
  },
  methods: {
    addInputParam(nameP, valueP) {
      this.pDataRequest.push({
        name: nameP,
        value: valueP
      });
    }, 
    CopyClipboard() {
      let link = this.getUrl;
      if (link != null && link != '') {
        this.showMessage = true;
        navigator.clipboard.writeText(link);
        console.log("Copy in ClipBoard : " + link);
      }
    },
    callWS() {
      this.getData = '';
      let link = '?pProt=' + this.URLProtocole + '&pUrl=' + this.urlRequest;
      let bodyRequest = {}; // { pKey: this.pKeyRequest, pFct: this.pFctRequest }
        
      for(let i = 0; i < this.pDataRequest.length; i++) {
        bodyRequest[this.pDataRequest[i].name] = this.pDataRequest[i].value;
        let _t = '';

        if (link != null && link != '') { 
          _t = '&'; 
        } else { 
          _t = '?'; 
        }
        // link &= if((link != null && link != ''),('?'),( '&')) & this.pDataRequest[i].name + '=' + this.pDataRequest[i].value
          
        link += _t + this.pDataRequest[i].name + '=' + this.pDataRequest[i].value;
      }

      this.getUrl = window.location.origin + link;

      // console.log(this.getUrl)

      console.log(this.urlRequest)
    
      fetch(this.URLProtocole + "://" + this.urlRequest,
        {
          mode: "cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(bodyRequest)
        }
      )
      .then(function(res) {
        return res.json();
      })
      .then(res => {
        console.log(res)
        this.getData = res;
      }) 
    },
  },
}
</script>

<style>

h2 {
  font-size: 1.3rem;
}
.fillWidth {
  width: 100%;
}
label.label {
  padding: 10px 16px;
}
.label {
  width: 150px;
  margin-right: 2rem;
}
.col {
  width: 45%;
}
.column {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.input_purple {
  width: calc(100% - 220px - 2rem);
}
.column1 {
  width: calc(182px + 2rem);
}
.column2 {
  width: calc(100% - 182px - 2rem);
}
.container_btn {
  margin-top: 2rem;
}
.container_URL {
  word-wrap: break-word;
}
.container_URL .content_URL {
  text-decoration: underline;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.container_URL .content_URL:hover {
  color: var(--accent-base);
}
.container_resultat {
  margin-top: 2rem;
  border: 1px dashed var(--orange-base);
  padding: 1rem;
}
.container_resultat pre.prettyprint {
  border: transparent;
}
</style>