<template>
  <div id="app">
    <TheHeader/>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>

    <TheFooter />

    <TheScrollUp />

    <a
        href="https://support.graphibox.fr/"
        target="_blank"
        rel="noopener noreferrer"
        title="Connexion - Espace Client / Support - Graphibox"
        class="the_fab supportbtn"
      >
        <i class="fal fa-question-circle"></i>
        Support
      </a>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import TheScrollUp from "./components/TheScrollUp.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    TheScrollUp,
  }
};
</script>

<style>
#app {
  font-family: "Sen", Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

html,
body {
  scroll-behavior: smooth;
  margin: 0;
  color: var(--text-col);
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: 0.3s ease;
}
p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 18px;
}
::-webkit-scrollbar-track {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-base);
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-base);
}
:root {
  scrollbar-color: var(--primary-base) #eee;
  scrollbar-width: 18px;
  color-scheme: normal !important;
  /* --primary-base: #00a5a9;
	--secondary-base: #7b61ff;
	--color-3: #ee858d;
	--primary-base-75: rgba(229, 71, 83, 0.75);
	--text-col: #090b37; */

  --primary-base: #2ec5ce;
  --primary-light: #75e3ea;
  --primary-lighter: #e7f8f9;
  --secondary-base: #7b61ff;
  --secondary-light: #f1e4ff;
  --secondary-dark: #3d20ae;
  --secondary-darker: #200d6a;
  --accent-base: #fe9a22;
  --accent-light: #ffc278;
  --accent-lighter: #fff2e3;
  --accent-light: #fff2e3;
  --accent2-base: #f22bb2;
  --accent2-light: #ff72d2;
  --accent2-light2: #ffc3ff;
  --accent2-lighter: #ffe8f7;
  --accent3-light: #ffffa7;
  --text-col: #17181b;
  --greyscale-600: #81868a;
  --greyscale-500: #9ba0a5;
  --greyscale-400: #bec1c5;
  --greyscale-200: #e8eaed;
  --greyscale-100: #f1f3f4;
  --greyscale-50: #fcfcfd;
  --orange-base: #fe9a22;
  --rose-base: #f22bb2;
  --grey-base: #606368;
}
.site_container {
  margin: 0 5%;
  width: 90%;
}


/*************
	BUTTON
*************/
.the_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 16px;
  /* margin a suprimer ? */
  margin: 5px 8px;
  border: 2px black solid;
  transition: 0.5s ease;
  cursor: pointer;
  width: fit-content;
}
.the_btn.b_primary {
  border-color: var(--primary-base);
  background-color: var(--primary-base);
  color: white;
}
.the_btn.b_primary:hover {
  border-color: var(--primary-light);
  background-color: var(--primary-light);
  color: white;
}
.the_btn.b_secondary {
  border-color: var(--secondary-base);
  background: #fff;
  color: var(--secondary-base);
}
.the_btn.b_secondary:hover {
  border-color: var(--secondary-base);
  background: var(--secondary-light);
  color: var(--secondary-base);
}
.the_btn.b_accent {
  border-color: var(--accent-base);
  background: #fff;
  color: var(--accent-base);
}
.the_btn.b_accent:hover {
  border-color: var(--accent-base);
  background: var(--accent-light);
  color: var(--accent-base);
}
.the_btn.b_accent2 {
  border-color: var(--accent2-base);
  background: #fff;
  color: var(--accent2-base);
}
.the_btn.b_accent2:hover {
  border-color: var(--accent2-base);
  background: var(--accent2-light2);
  color: var(--accent2-base);
}
.the_fab {
  border: 1px solid var(--secondary-base);
  background-color: var(--secondary-base);
  color: #ffffff;
  padding: 10px 16px;
  border-radius: 64px;
  font-size: 14px;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.the_fab i {
  font-size: 24px;
  margin-right: 0.5rem;
}
.the_fab:hover {
  box-shadow: 0px 5px 15px #17181b61;
}

/*****************
	TEXT INPUT
*****************/
.container_input {
  width: 40%;
  padding: 1rem;
  border: 1px dashed var(--secondary-base);
  display: flex;
  flex-direction: column;
}
._purple {
  background-color: var(--secondary-darker);
}
label {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
}
._purple label {
  color: white;
}
.the_input_text {
  appearance: none;
  border: 1px solid var(--grey-base);
  border-radius: 8px;
  padding: 10px 16px;
  background-color: var(--greyscale-100) !important;
  font-size: 18px;
  /* font-family: "Sen"; */
  
  transition: 0.3s ease-in;
  /* margin-top: 1rem; */
}
.input_grey,
.input_grey:-internal-autofill-selected {
  background-color: var(--greyscale-100) !important;
  margin-top: 1rem;
}
.input_grey:hover {
  background-color: var(--greyscale-200);
  transition: 0.3s ease-in;
}
.input_grey:focus {
  border: 2px solid var(--text-col);
  outline: none;
}
.input_purple {
  background-color: var(--secondary-dark) !important;
  color: rgb(253, 255, 255);
  margin-top: 1rem;
  border: none;
}
.input_purple::placeholder {
  color: var(--secondary-light);
}
.input_purple:hover {
  background-color: var(--secondary-base);
  transition: 0.3s ease-in;
}
.input_purple:focus {
  border: 2px solid white;
  outline: none;
}
.container_input_btn {
  display: flex;
}
.container_input_btn .the_btn {
  margin: 0;
}
.container_input_btn .the_input_text {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  border-right: none;
}
.container_input_btn .the_btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid var(--primary-base);
}
.container_textarea {
  display: flex;
  flex-direction: column;
}
.container_checkbox {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}
.container_checkbox label {
  text-transform: initial;
  font-weight: 400;
  font-size: 0.9rem;
}
.the_checkbox {
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--secondary-base);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-right: 0.8rem;
}
.the_checkbox:active,
.the_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.the_checkbox:checked {
  background-color: var(--secondary-base);
  border: 1px solid white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
.the_checkbox:checked:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: white;
}
.the_checkbox:hover {
  background-color: var(--secondary-dark);
}
.checkboxLink {
  font-weight: 600;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.checkboxLink:hover {
  color: var(--secondary-base);
}


</style>
